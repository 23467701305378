<template>
    <div>
        <div class="search-banner">
            <div class="topWrap">
                <div class="topTitle">
                    <span>找贴图 · 上北纬</span>
                </div>
                <div class="outer-container">
                    <div class="sel-type">
                        <div class="select-on">贴图</div>
                        <img src="https://cdn.code.znzmo.com/img/mainHome/dropDown.png" alt="">
                        <div class="s-zw"></div>
                        <div class="sel-type-item">
                            <div class="item">贴图</div>
                            <div class="item">材质</div>
                            <div class="item">灯光</div>
                        </div>
                    </div>
                    <div class="search-ipt">
                        <input type="text" placeholder="输入关键词，搜索贴图">
                    </div>
                    <div class="search-btn">
                        <img src="http://dingzi-jsfile.oss-cn-shanghai.aliyuncs.com/newSu/ss-icon-23.png" alt="">
                    </div>
                </div>
                <div class="more-tj">
                    <span>热门搜索：</span>
                    <a href="">ENS材质</a>
                    <a href="">木地板贴图</a>
                    <a href="">免费贴图</a>
                    <a href="">硅藻泥</a>
                    <a href="">高清岩板</a>
                    <a href="">无缝生成器</a>
                </div>
            </div>
            <div class="swiper">
                <img src="https://image4.znzmo.com/dPWFHjtfT2.jpg?x-oss-process=image/auto-orient,1" alt="">
                <ul class="slick-dots slick-dots-bottom">
                    <li class="slick-active"><button>1</button></li>
                    <li class=""><button>2</button></li>
                    <li class=""><button>3</button></li>
                    <li class=""><button>4</button></li>
                    <li class=""><button>5</button></li>
                </ul>
            </div>
        </div>
        <div class="imgListWrapper">
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/1.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">木材</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/2.1.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">石材</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/3.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">布类</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/4.1.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">壁纸</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/5.1.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">金属</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/6.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">皮革</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/7.1.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">凹凸贴图</a>
            </div>
            <div class="singleWrap">
                <img src="https://image2.znzmo.com/tietuCategory/8.1.png?x-oss-process=image/auto-orient,1" alt="">
                <a href="" class="tip">装饰画</a>
            </div>
            <div class="singleWrap">
                <img src="https://cdn.code.znzmo.com/tietu-optimize/allClassBtn.png?x-oss-process=image/auto-orient,1"
                    alt="">
                <a href="" class="tip">地毯</a>
            </div>
            <div class="singleWrap moreWrap">
                <img src="https://cdn.code.znzmo.com/tietu-optimize/moreBtn.png" alt="">
                <a href="" class="tip">更多</a>
            </div>
        </div>
        <!-- <div class="navBar">
        <div class="tabGroup">
            <a href="" class="tabItem activeItem">
                <img src="https://cdn.code.znzmo.com/tietu-optimize/value0Icon.png" alt="">
                推荐贴图
            </a>
            <a href="" class="tabItem">
                <img src="https://cdn.code.znzmo.com/tietu-optimize/value1Icon.png" alt="">
                无缝贴图
            </a>
            <a href="" class="tabItem">
                <img src="	https://cdn.code.znzmo.com/tietu-optimize/value2Icon.png
                " alt="">
                国外贴图
            </a>
            <a href="" class="tabItem">
                <img src="https://cdn.code.znzmo.com/tietu-optimize/value4Icon.png" alt="">
                免费材质库
            </a>
            <a href="" class="tabItem">
                <img src="	https://cdn.code.znzmo.com/tietu-optimize/guangyuwang.png" alt="">
                光域网
            </a>
            <a href="" class="tabItem">
                <img src="	https://cdn.code.znzmo.com/tietu-optimize/value3Icon.png" alt="">
                免费贴图
            </a>
        </div>
    </div> -->
        <div class="w1703">
            <div class="RecommendWordWrap">
                <div class="RecommendTitle">推荐·优选</div>
                <div class="RecommendList">
                    <div class="RecommendListItem activeItem">
                        <a href="">木地板</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">大理石</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">花纹布类</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">中式壁纸</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">常用金属</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">常用皮革</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">黑白凹凸</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">抽象画</a>
                    </div>
                    <div class="RecommendListItem">
                        <a href="">常用地毯</a>
                    </div>
                </div>
            </div>
            <div class="tieTuFloor">
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/fa7748e08d1114d3b13f87bb0e6e673f_0.jpg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">国外高清无缝木地板木纹</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/E08860335A7CB4A0376D6B5D301D652D.jpg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">深色无缝胡桃木纹</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/1648620276598_207.jpeg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">无缝胡桃木木纹 木饰面</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/1e222b4f487e4f475f807a914d415444c30b71c9_2.jpg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">复古木纹无缝贴图</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/c83946e16e85fecc556b4367adc79a87_0.jpg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">高清无缝木纹贴图(845)</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/dd985e5957e70e871180ad95bce25dbe_0.jpg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">高清无缝木纹贴图</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/1611826216871_8462.jpeg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">高清无缝木饰面樱桃木</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/1585273649879_1279.jpeg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">高清木纹木板无缝贴图</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/1e222b4f487e4f475f807a914d415444c30b71c9_2.jpg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">复古木纹无缝贴图</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="floorItem">
                    <a href="">
                        <div class="img">
                            <img src="https://cdn.code.znzmo.com/tietu-optimize/wufengTag.png" class="tag" alt="">
                            <img src="https://image6.znzmo.com/1623288589080_9624.jpeg?x-oss-process=style/list"
                                class="imgCon" alt="">
                        </div>
                        <div class="info">
                            <div class="infoTitle">
                                <a href="">灰色直木纹无缝</a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <ul class="pagination">
                <li class="pagination-prev">
                    <a href="">
                        <span></span>
                        <span>上一页</span>
                    </a>
                </li>
                <li class="pagination-item pagination-item-active">
                    <a href="">1</a>
                </li>
                <li class="pagination-item">
                    <a href="">2</a>
                </li>
                <li class="pagination-next">
                    <a href="">
                        <span>下一页</span>
                        <span></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: ''
            }
        },
    };
</script>

<style lang="less" scoped>
    .search-banner {
        width: 100%;
        height: 380px;
        position: relative;

        .topWrap {
            position: absolute;
            top: 69px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 100;
            width: 770px;

            .topTitle {
                text-align: center;
                font-size: 45px;
                margin-bottom: 48px;
                color: #fff;

                span {
                    cursor: pointer;
                }
            }

            .outer-container {
                position: relative;
                width: 768px;
                height: 48px;
                display: flex;
                align-items: center;

                // border-radius: 8px;

                .sel-type {
                    width: 128px;
                    height: 48px;
                    vertical-align: top;
                    border-radius: 8px 0 0 8px;
                    background-color: #fff;
                    position: relative;
                    padding: 0 11px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                    cursor: pointer;

                    .select-on {
                        padding-left: 15px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 11px;
                        right: 0;
                        width: 1px;
                        height: 26px;
                        background: #e5e5e5;
                        z-index: 99;
                    }

                    .s-zw {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        height: 6px;
                    }

                    .sel-type-item {
                        position: absolute;
                        width: 100%;
                        top: 54px;
                        left: 0;
                        background-color: #fff;
                        border-radius: 0 0 8px 8px;
                        padding: 8px 0;
                        display: none;

                        .item {
                            padding: 10px 12px 10px 26px;
                            font-size: 16px;
                            position: relative;

                            &:hover {
                                &::after {
                                    position: absolute;
                                    top: 5px;
                                    left: 0;
                                    bottom: 0;
                                    height: 32px;
                                    border-right: 4px solid #536c79;
                                    content: "";
                                }
                            }
                        }
                    }

                    &:hover {
                        .sel-type-item {
                            display: block;
                        }
                    }
                }

                .search-ipt {
                    flex: 1;
                    background-color: #fff;

                    input {
                        width: 100%;
                        padding: 0 20px;
                        font-size: 14px;
                        letter-spacing: 1px;
                        border: none;
                        outline: none;
                        line-height: 48px;
                        height: 48px;
                        color: rgb(0 0 0 / 60%);
                    }
                }

                .search-btn {
                    width: 100px;
                    height: 48px;
                    background-color: #546c78;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0 8px 8px 0;
                    cursor: pointer;
                }

            }

            .more-tj {
                width: 100%;
                margin-top: 11px;
                font-size: 12px;

                span {
                    color: #fff;
                    margin-left: 27px;
                }

                a {
                    margin-left: 20px;
                }
            }
        }

        .swiper {
            img {
                width: 100%;
                height: 380px;
                -o-object-fit: cover;
                object-fit: cover;
            }

            .slick-dots {
                position: absolute;
                bottom: 16px;
                display: block;
                width: 100%;
                height: 6px;
                margin: 0;
                padding: 0;
                text-align: center;
                list-style: none;

                li {
                    position: relative;
                    display: inline-block;
                    margin: 0 6px;
                    padding: 0;
                    text-align: center;
                    vertical-align: top;

                    button {
                        height: 6px;
                        width: 24px;
                        background: #fff;
                        box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
                        opacity: 0.41;
                        border-radius: 3px;
                        font-size: 0;
                        border: 0;
                        color: transparent;
                        display: block;
                        transition: all .5s;
                        outline: none;
                        cursor: pointer;
                    }
                }

                li.slick-active {
                    button {
                        width: 44px;
                        opacity: 0.81;
                    }
                }
            }
        }

    }

    .imgListWrapper {
        width: 100%;
        background-color: #fff;
        min-width: 1440px;
        padding: 30px 28px 30px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 12px 16px 0px rgb(0 0 0 / 5%);

        .singleWrap {
            width: 9.27%;
            height: 77px;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            font-size: 18px;
            text-shadow: #666 -2px 1px 1px;
            text-align: center;
            line-height: 77px;
            overflow: hidden;

            img {
                position: absolute;
                width: 180px;
                height: 128px;
                border-radius: 4px;
                top: 0;
                left: 0;
                z-index: 1;
            }

            .tip {
                position: relative;
                z-index: 2;
            }
        }

        .moreWrap {
            width: 4.27%;
        }
    }

    .navBar {
        width: 100%;
        min-width: 1440px;
        padding: 68px 0 0 0;
        display: flex;
        justify-content: center;
        position: relative;

        .tabGroup {
            display: flex;
            width: 1240px;
            align-items: center;
            justify-content: space-between;

            .tabItem {
                width: 190px;
                height: 68px;
                background: #fff;
                box-shadow: 0px 10px 16px 0px rgb(0 0 0 / 7%);
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-size: 20px;
                font-weight: 500;
                border-radius: 8px 8px 0px 0px;
                cursor: pointer;
                color: #333 !important;

                img {
                    width: 26px;
                    height: 26px;
                    margin-right: 14px;
                    object-fit: contain;
                }
            }

            .activeItem {
                position: relative;
                background: linear-gradient(270deg, #e8344b 0%, #ed314a 100%);
                box-shadow: 0px 10px 16px 0px rgb(0 0 0 / 7%);
                color: #fff !important;

                &::after {
                    content: '';
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    bottom: -29px;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-top: 20px solid #e8344b;
                }
            }
        }
    }

    .RecommendWordWrap {
        padding: 56px 0px 0px;

        .RecommendTitle {
            height: 40px;
            font-size: 40px;
            font-weight: 600;
            color: #333;
            line-height: 40px;
        }

        .RecommendList {
            width: 100%;
            width: 100%;
            display: flex;
            display: flex;
            flex-direction: row;
            flex-direction: row;
            flex-wrap: wrap;
            flex-wrap: wrap;
            margin-top: 8px;
            margin-top: 8px;

            .RecommendListItem {
                position: relative;

                a {
                    display: inline-block;
                    padding: 0 47px;
                    height: 42px;
                    line-height: 42px;
                    vertical-align: middle;
                    text-align: center;
                    border: 1px solid #888;
                    border-radius: 6px;
                    margin-right: 20px;
                    margin-top: 27px;
                    color: #555;
                    font-size: 16px;
                    cursor: pointer;
                }
            }

            .activeItem a {
                background-color: #333;
                color: #fff;
            }
        }
    }

    .tieTuFloor {
        display: flex;
        flex-wrap: wrap;
        margin-top: 28px;
        width: 100%;

        .floorItem {
            position: relative;
            width: 340px;
            height: 300px;
            margin-right: 25px;
            margin-bottom: 30px;
            cursor: pointer;

            .img {
                border-radius: 8px;
                width: 100%;
                height: 252px;
                overflow: hidden;
                position: relative;
            }

            .imgCon {
                width: 100%;
                height: 252px;

            }

            .tag {
                position: absolute;
                top: 0;
                left: 0;
                width: 65px !important;
                height: 31px !important;
                border: none;
            }

            .info {
                padding: 16px 0;
                margin-bottom: 20px;

                .infoTitle {
                    font-weight: 600;
                    float: left;
                    color: #333;
                    font-size: 14px;
                    height: 16px;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    a {
                        color: #333;
                    }
                }
            }
        }

        .floorItem:nth-child(5n) {
            margin-right: 0;
        }
    }

    .floorItem:nth-child(5) {
        margin-right: 0;
    }

    .pagination {
        display: flex;
        display: flex;
        justify-content: center;
        justify-content: center;
        align-items: center;
        align-items: center;
        margin-bottom: 60px;
        margin-bottom: 60px;

        li {
            height: 40px;
            height: 40px;
            width: 40px;
            width: 40px;
            line-height: 40px;
            line-height: 40px;
        }

        .pagination-item {
            min-width: 32px;
            min-width: 32px;
            text-align: center;
            text-align: center;
            list-style: none;
            list-style: none;
            background-color: #fff;

            border: 1px solid #d9d9d9;
            border-radius: 4px;
            outline: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: none;
            width: 40px;
            height: 40px;
            background: #fafafa;
            border-radius: 8px;
            margin: 0 6px;

            a {
                color: #333;
            }
        }

        .pagination-item-active {
            font-weight: 400;
            font-size: 14px;
            background: #333;

            a {
                color: #fff;
            }
        }

        .pagination-prev {
            width: 78px;
            height: 40px;
            background: #fafafa;
            border-radius: 8px;

            a {
                color: #333;
            }

            span:nth-child(1) {
                display: inline-block;
                background: url(https://cdn.code.znzmo.com/sucai/img/images/pagepreIcon.png) no-repeat center;
                background-size: cover;
                width: 14px;
                height: 15px;
                vertical-align: middle;
            }
        }

        .pagination-next {
            width: 78px;
            height: 40px;
            background: #fafafa;
            border-radius: 8px;

            a {
                color: #333;
            }

            span:nth-child(2) {
                display: inline-block;
                background: url(https://cdn.code.znzmo.com/sucai/img/images/pagenextIcon.png) no-repeat center;
                background-size: cover;
                width: 14px;
                height: 15px;
                vertical-align: middle;
            }
        }
    }

    @media only screen and (max-width: 1800px) {
        .tieTuFloor {
            .floorItem {
                width: 280px;
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .tieTuFloor {
            .floorItem {
                width: 220px;
                height: 200px;

                .img {
                    height: 182px;
                }
            }
        }
    }

    // @media only screen and (max-width: 1000px) {
    //     .search-banner {
    //         display: none;
    //     }

    //     .imgListWrapper {
    //         min-width: unset;
    //         flex-wrap: wrap;
    //         padding: 10px;

    //         .singleWrap {
    //             width: 19%;
    //             margin-bottom: 5px;
    //             line-height: 1.2;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //         }
    //     }

    //     .RecommendWordWrap .RecommendTitle {
    //         text-align: center;
    //     }

    //     .RecommendWordWrap .RecommendList {
    //         .RecommendListItem {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;

    //             a {
    //                 width: 100%;
    //                 margin-right: 0;
    //             }
    //         }

    //         .RecommendListItem:nth-child(2n) {
    //             margin-right: 0;

    //             a {
    //                 margin-right: 0;
    //             }
    //         }
    //     }

    //     .tieTuFloor {
    //         .floorItem {
    //             width: calc(50% - 5px);
    //             margin-right: 10px;
    //         }

    //         .floorItem:nth-child(5n) {
    //             margin-right: 10px;
    //         }

    //         .floorItem:nth-child(2n) {
    //             margin-right: 0;
    //         }
    //     }
    // }
</style>